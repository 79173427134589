@tailwind base;
@tailwind components;
@tailwind utilities;

.spinner-border {
    display: inline-block;
    border-color: #e0e0e0;
    border-top-color: #3498db;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
}

/* Define animations in your CSS */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slideUp {
    from {
        transform: translateY(20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

/* Apply animations to the relevant classes */
.fade-in {
    animation: fadeIn 0.5s ease-out;
}

.slide-up {
    animation: slideUp 0.6s ease-out;
}

.sidebar-icon {
    min-width: 1.5rem; /* Ensures consistent spacing */
    font-size: 1rem; /* Sets a uniform size */
}

/* Landing Page List Styls */
/* List Item Card */
.list-item {
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    margin-bottom: 1rem; /* Spacing between list items */
}
  
  /* Hover Effect */
  .list-item:hover {
    transform: translateX(5px); /* Slight right shift on hover */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    background-color: #f8fafc;
  }
  
  /* Icon Style */
  .list-icon {
    font-size: 1.5rem;
    color: #3490dc; /* Blue color */
    margin-right: 1rem;
    transition: transform 0.2s ease, color 0.2s ease;
  }
  
  .list-item:hover .list-icon {
    color: #1c3d5a; /* Darker blue on hover */
  }
  
  /* Content Layout */
  .list-content {
    flex-grow: 1;
  }
  
  .list-title {
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: 0.25rem;
  }
  
  .list-description {
    color: #4a5568; /* Text color */
    font-size: 0.875rem;
  }
  
  /* Button Style */
  .list-button {
    background-color: #3490dc;
    color: #fff;
    border-radius: 9999px; /* Rounded full */
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    transition: background-color 0.2s ease;
  }
  
  .list-button:hover {
    background-color: #1c3d5a; /* Darker shade on hover */
  }
  